import React, { useState, useEffect } from 'react'
import { graphql } from "gatsby"

import Layout from '../../components/layout'
import SEO from '../../components/seo'

import "../../styles/main.scss"

const Pears = () => {
  const [loader, setLoader] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setLoader(false)
    }, 600)
  }, [])

  return loader ? (
    <div className="loader" />
  ) : (
    <Layout>
      <SEO
        title="Forcera"
        keywords={[`software development`, `ai`, `big data`, `cloud engineering`, `machine learning`, `data strategy`]}
      />
      <article>
        <div className="container">
          <h2>PEARS - Procurement and Expenditure Analysis for Rural Sustainability</h2>
          <figure>
            <img
             src={"/auroral.png"}
            />
          </figure>
          <h3>AURORAL</h3>
          <p>
          <a className="a-blue" href="https://www.auroral.eu/" target="_blank">AURORAL</a>, an H2020 initiative, is transforming rural communities across Europe by bridging the digital divide. With its open and interoperable Internet of Things (IoT) ecosystem, AURORAL fosters innovation, boosts economic growth, and creates sustainable solutions for rural areas. Large-scale pilots in seven European regions demonstrate the power of connected smart ecosystems to address societal challenges and unlock rural potential.
          </p>
          <hr />
          <h3>PEARS</h3>
          <p className="mt-2">
          As part of the AURORAL framework, PEARS (Procurement and Expenditure Analysis for Rural Sustainability) revolutionizes how public organizations manage procurement and spending. By leveraging open data and cutting-edge analytics, PEARS empowers public bodies and citizens with transparency, efficiency, and actionable insights.
          </p>
          <h3>PEARS at a Glance</h3>
          <ul className="ul-list">
            <li><strong>Transparency:</strong> Provides clear insights into public spending, fostering accountability and trust.</li>
            <li><strong>Efficiency:</strong> Helps public entities identify cost-saving opportunities and optimize procurement processes.</li>
            <li><strong>Inclusivity:</strong> Designed for both urban and rural communities, ensuring accessibility and utility across Europe.</li>
            <li><strong>Innovation:</strong> Powered by advanced analytics and AI-driven insights integrated with AURORAL's middleware.</li>
          </ul>
          <h3>Key Features</h3>
          <p>
          PEARS offers a comprehensive suite of features designed to transform public procurement:
          </p>
          <ul className="ul-list">
            <li><strong>360º View of Public Spending:</strong> Analyze procurement trends, supplier performance, and benchmarking with other entities.</li>
            <li><strong>Optimized Decision-Making:</strong> Offers data-driven recommendations for reducing costs and improving supplier relationships.</li>
            <li><strong>Citizen Engagement:</strong> Makes procurement data accessible to citizens, promoting collaboration and trust in public institutions.</li>
            <li><strong>Scalability:</strong> Seamlessly integrates with AURORAL's ecosystem, enabling widespread adoption across European regions.</li>
          </ul>
          <h3>Why PEARS Matters</h3>
          <p>
          Public spending accounts for 14% of the EU's GDP, yet many rural communities face challenges in managing these resources effectively. PEARS equips public entities with the tools to enhance governance, reduce waste, and improve service delivery, ensuring that every euro spent benefits communities directly.
          </p>
          <h3>Driving Change Together</h3>
          <p>
          The PEARS project aligns with AURORAL's mission to empower rural areas with innovative, data-driven solutions. By combining technology and transparency, PEARS ensures that rural communities can thrive in a connected and sustainable future.
          </p>
          <figure className="ue-horizon-logo">
            <div className="logo-container">
              <img
                src={"/horizon-2020.jpeg"}
              />
              <img
                src={"/auroral.png"}
              />
            </div>
            <figcaption>This project has received cascading funding from the European Union's Horizon 2020 research and innovation programme.</figcaption>
          </figure>
        </div>
      </article>
    </Layout>
  )
}

export default Pears;

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
