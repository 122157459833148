// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-ai-regio-js": () => import("./../../../src/pages/projects/ai-regio.js" /* webpackChunkName: "component---src-pages-projects-ai-regio-js" */),
  "component---src-pages-projects-digiprime-js": () => import("./../../../src/pages/projects/digiprime.js" /* webpackChunkName: "component---src-pages-projects-digiprime-js" */),
  "component---src-pages-projects-minecare-js": () => import("./../../../src/pages/projects/minecare.js" /* webpackChunkName: "component---src-pages-projects-minecare-js" */),
  "component---src-pages-projects-offtwain-js": () => import("./../../../src/pages/projects/offtwain.js" /* webpackChunkName: "component---src-pages-projects-offtwain-js" */),
  "component---src-pages-projects-pausa-js": () => import("./../../../src/pages/projects/pausa.js" /* webpackChunkName: "component---src-pages-projects-pausa-js" */),
  "component---src-pages-projects-pears-js": () => import("./../../../src/pages/projects/pears.js" /* webpackChunkName: "component---src-pages-projects-pears-js" */),
  "component---src-pages-projects-safra-js": () => import("./../../../src/pages/projects/safra.js" /* webpackChunkName: "component---src-pages-projects-safra-js" */),
  "component---src-pages-projects-zdmp-js": () => import("./../../../src/pages/projects/zdmp.js" /* webpackChunkName: "component---src-pages-projects-zdmp-js" */)
}

