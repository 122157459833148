import React, { useState, useEffect } from 'react'
import { graphql } from "gatsby"

import Layout from '../../components/layout'
import SEO from '../../components/seo'

import "../../styles/main.scss"

const Pausa = () => {
  const [loader, setLoader] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setLoader(false)
    }, 600)
  }, [])

  return loader ? (
    <div className="loader" />
  ) : (
    <Layout>
      <SEO
        title="Forcera"
        keywords={[`software development`, `ai`, `big data`, `cloud engineering`, `machine learning`, `data strategy`]}
      />
      <article>
        <div className="container">
          <h2>PAUSA - Predictive Analytics for Urban Sustainability</h2>
          <figure>
            <img
             src={"/nebulous-logo.png"}
            />
          </figure>
          <h3>NebulOuS Project</h3>
          <p>
          <a className="a-blue" href="https://nebulouscloud.eu/" 
          target="_blank">NebulOuS</a> is a European project that aims to become the go-to Operating System for the Cloud Computing Continuum.
          </p>
          <p>
          This project aims to revolutionize cloud and fog computing brokerage through the development of advanced provisioning tools, a unique Meta Operating System, and the comprehensive NebulOuS solution.
          </p>
          <hr />
          <h3>PAUSA at a Glance</h3>
          <p>
          As part of the NebulOuS framework, PAUSA leverages the Meta OS capabilities to address critical urban sustainability challenges. In partnership with Instituto Piaget, PAUSA focuses on utilizing advanced predictive analytics to optimize resource allocation, enhance sustainability, and empower cities to achieve their green goals.
          </p>
          <p>
          This solution combines cutting-edge technology with practical urban management needs through these key components:
          </p>
          <ul className="ul-list">
            <li><strong>Smart Resource Management:</strong> Leverages NebulOuS' Meta OS to predict urban resource needs and optimize their usage.</li>
            <li><strong>Sustainability Focus:</strong> Drives data-driven decisions to reduce waste, improve energy efficiency, and support greener cities.</li>
            <li><strong>Advanced Analytics:</strong> Integrates IoT data, digital twins, and machine learning models for actionable urban insights.</li>
            <li><strong>Collaborative Innovation:</strong> Developed in partnership with Instituto Piaget, ensuring a research-driven, impactful solution.</li>
          </ul>
          <h3>Key Features</h3>
          <p>
          PAUSA offers a comprehensive suite of features designed to transform urban sustainability:
          </p>
          <ul className="ul-list">
            <li><strong>Predictive Modeling:</strong> Uses real-time data from sensors and IoT devices to forecast urban resource demands and environmental impacts.</li>
            <li><strong>Dynamic Adaptation:</strong> Supports on-the-fly adjustments to application hosting and resource provisioning for optimal performance.</li>
            <li><strong>Sustainability Metrics:</strong> Tracks urban sustainability indicators, helping cities measure progress toward their environmental goals.</li>
            <li><strong>Interoperability:</strong> Seamlessly integrates with NebulOuS' cloud and fog ecosystems to support diverse urban infrastructures.</li>
          </ul>
          <h3>Why PAUSA Matters</h3>
          <p>
          Urban areas face increasing pressure to manage resources efficiently while reducing environmental impacts. PAUSA equips city planners and stakeholders with cutting-edge tools to address these challenges, ensuring a smarter, greener, and more sustainable future.
          </p>
          <p>
          Together, NebulOuS and PAUSA embody a transformative approach to urban sustainability. By combining NebulOuS' Meta OS innovations with PAUSA's predictive analytics expertise, we're shaping the future of cities—one where resources are managed intelligently, sustainability is prioritized, and citizens thrive.
          </p>
          <figure className="ue-horizon-logo">
            <div className="logo-container">
              <img
                src={"/horizon-2020.jpeg"}
              />
              <img
                src={"/nebulous-logo.png"}
              />
            </div>
            <figcaption>The PAUSA has indirectly received funding from the European Union’s Horizon Europe programme, via the NebulOuS Open Call #1 issued and executed under the NebulOuS project (Grant Agreement no.101070516).</figcaption>
          </figure>
        </div>
      </article>
    </Layout>
  )
}

export default Pausa;

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
` 